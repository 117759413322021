/* ResponseChat.module.css */

.chatContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	/* Ensure it fills the parent height */
}

.messagesContainer {
	flex-grow: 1;
	overflow-y: auto;
	min-height: 0;
}

.popupBlockedContainer,
.stayButtonContainer,
.redirectButtonContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.popupBlockedButton,
.stayButton,
.redirectButton,
.showAnimationButton,
.copyLinkButton {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #222222;
	font-size: 14px;
	margin-right: 10px;
	margin-bottom: 15px;
	background: #C1C1C1;
	padding: 5px;
	border-radius: 10px;
	border: 0px;
}

.popupBlockedButtonActive,
.stayButtonActive,
.redirectButtonActive,
.showAnimationButtonActive,
.copyLinkButtonActive {
	background: #A1A1A1;
	/* Slightly darker background when clicked */
}

/* Added blinking animation for the copy link button */
@keyframes blink {
	0% {
		background: #C1C1C1;
	}

	50% {
		background: #FFD700;
	}

	100% {
		background: #C1C1C1;
	}
}

.blink {
	animation: blink 0.75s ease-in-out 3;
}