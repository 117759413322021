.inputContainer {
	display: flex;
	align-items: center;
	background-color: #2f2f2f;
	padding: 10px 15px;
	border-radius: 25px;
	max-width: 600px;
	width: 90%;
	margin: 0 auto;
	position: relative;
}

textarea {
	background: transparent;
	border: none;
	color: #ffffff;
	flex: 1;
	font-size: 16px;
	line-height: 1.5;
	resize: none;
	outline: none;
	padding: 10px;
	min-height: calc(1.5em + 20px);
	max-height: calc(1.5em * 4 + 20px);
	overflow-y: auto;
	box-sizing: border-box;
	transition: height 0.2s ease;
	width: 300px;
}

@media (max-width: 768px) {
	.inputContainer {
		padding: 8px 12px;
	}
}

@media (max-width: 480px) {
	textarea {
		font-size: 14px;
	}
}