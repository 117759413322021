.inputContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	max-width: 600px;
	width: 90%;
	margin: 0 auto;
	flex-direction: column;
}

.querySectionText {
	font-size: 30px;
	margin-bottom: 4px;
}

.querySectionText_2 {
	font-size: 12px;
	margin-bottom: 20px;
}