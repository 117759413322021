/* ShareTile.module.css */

.shareButtons {
	display: flex;
	gap: 10px;
}

.shareButton {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: none;
	background: none;
	/* Remove any background */
	cursor: pointer;
}

.shareButton svg {
	color: #c5c5c5;
	/* Control the icon foreground color */
	transition: color 0.3s ease;
	/* Optional hover effect */
}

.shareButton:hover svg {
	color: #808080;
	/* Hover color change */
}