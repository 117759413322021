/* ChatMessage.module.css */

.messageListItem {
    display: flex;
    margin: 10px 0;
}

.userMessage {
    flex-direction: row-reverse;
}

.assistantMessage {
    flex-direction: row;
}

.userMessage .messageContent {
    background-color: #2f2f2f;
}

.assistantMessage .messageContent {
    background-color: transparent;
}

.messageContent {
    max-width: 80%;
    padding: 10px;
    border-radius: 10px;
    overflow-wrap: break-word;
    text-align: left;
}

.messageText {
    white-space: pre-wrap;
}