* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.pageContainer {
  display: flex;
  min-height: 100vh;
  background: #212121;
  font-family: system-ui, sans-serif;
  overflow: hidden;
}

@media (max-width: 768px) {
  .pageContainer {
    flex-direction: column;
  }
}

/* Button Styles */
.button {
  background-color: transparent;
  border: none;
  color: #B4B4B4;
  font-size: 20px;
  cursor: pointer;
}

.button:hover {
  color: #ECECEC;
}